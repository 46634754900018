search
<template>
  <div class="user">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <back class="back" />
        <el-select v-model="contractStatus" placeholder="生成状态" @change="onSearch" clearable style="margin-right: 20px">
          <el-option label="否" value="0"></el-option>
          <el-option label="是" value="1"></el-option>
        </el-select>
        <el-select v-model="tendersType" placeholder="招标类型" @change="onSearch" clearable style="margin-right: 20px">
          <el-option v-for="item in departmentList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="eligibleType" placeholder="应标资格" @change="onSearch" clearable>
          <el-option v-for="item in eligibleTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-autocomplete clearable style="margin-left: 20px" v-model="bidPeriod" :fetch-suggestions="getbidPeriodPagelist" placeholder="请搜索标期" @select="onSearch" value-key="bidPeriod" @change="onSearch"></el-autocomplete>
        <el-input v-model="tendersName" placeholder="请输入招标名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
      </div>
      <div class="table_list globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column align="center" min-width="55" label="" width="100">
            <template slot-scope="scope">
              <!-- 可以手动的修改label的值，从而控制选择哪一项 -->
              <!-- 注意：这里写 &nbsp 的目的是为了页面不显示内容，只显示单选操作 -->
              <el-radio class="radio" v-model="radio" :label="scope.row" @change="singleElection(scope.row)">&nbsp;</el-radio>
            </template>
          </el-table-column>
          <el-table-column label="序号" :show-overflow-tooltip="true" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="bidPeriod" label="标期" show-overflow-tooltip width="120"></el-table-column>
          <el-table-column prop="tendersLabel" label="标号" show-overflow-tooltip width="180"></el-table-column>
          <el-table-column prop="tendersName" label="招标名称" show-overflow-tooltip width=""></el-table-column>
          <el-table-column prop="tendersType" label="招标类型" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.tendersType == 1 ? "商超" : "食堂" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="eligibleType" label="应标资格" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.eligibleType == 1">平台资格</span>
              <span v-if="scope.row.eligibleType == 2">独标资格</span>
            </template>
          </el-table-column>
          <el-table-column prop="tendersTime" label="开标时间" show-overflow-tooltip width="120"></el-table-column>
          <el-table-column prop="effectiveEndTime" label="中标有效期" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ scope.row.effectiveStartTime }}至{{ scope.row.effectiveEndTime }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="生成状态" show-overflow-tooltip width="80" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.contractStatus == 0 ? "否" : scope.row.contractStatus == 1 ? "是" : "" }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    <div style="text-align: center; margin: 30px 0px 30px 0">
      <el-button type="primary" @click="onNext" :disabled="disabled">下一步</el-button>
    </div>
  </div>
</template>

<script>
export default {
  //   VueEditor
  // },
  data() {
    return {
      bidPeriodloading: false,
      bidPeriodlist: [],
      content: "",
      loading: false,
      submitLoding: false,
      tendersName: "", //标书名称
      tendersLabel: "", //标号
      bidPeriod: "", //标期
      tableData: [],
      disabled: true,
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      radioStatus: "", //当前选择的行的id
      radio: "",

      departmentList: [
        {
          value: "1",
          label: "商超",
        },
        {
          value: "2",
          label: "食堂",
        },
      ],
      contractStatus: "",
      tendersType: "",
      eligibleType: "",
      eligibleTypes: [
        {
          value: "1",
          label: "平台资格",
        },
        {
          value: "2",
          label: "独标资格",
        },
      ],
      roleList: [],
      startStatus: "",
      options: [
        {
          value: "0",
          label: "待评标",
        },
        {
          value: "2",
          label: "已评标",
        },
      ],
      status: ["待评标", "", "已评标"],
    }
  },

  created() {
    this.getportalPageList()
    // this.getbidPeriodPagelist();
  },
  methods: {
    singleElection(row) {
      this.radioStatus = row.tendersId
      if (this.radioStatus) {
        this.disabled = false
      }
    },
    onNext() {
      this.$router.push({
        path: "/contract/detail/newBuilt",
        query: {
          tendersId: this.radioStatus,
        },
      })
    },
    //标期下拉列表
    getbidPeriodPagelist(queryString, cb) {
      this.bidPeriodloading = true
      this.$axios
        .get(this.$api.bidPeriodPage, {
          params: {
            page: 1,
            pageSize: 500,
            bidPeriod: queryString,
          },
        })
        .then(res => {
          this.bidPeriodloading = false
          this.bidPeriodlist = res.data.result.list
          cb(this.bidPeriodlist)
        })
    },
    // 招标列表
    getportalPageList() {
      this.loading = true
      this.$axios
        .get(this.$api.getTenders, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            eligibleType: this.eligibleType,
            // tendersLabel: this.tendersLabel
            contractStatus: this.contractStatus,
            tendersName: this.tendersName,
            bidPeriod: this.bidPeriod,
            tendersType: this.tendersType,
          },
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getportalPageList()
      // this.getbidPeriodPagelist();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.tableData = []
      this.getportalPageList()
    },
    // 换页
    handleCurrentChange() {
      this.page += 1
      this.tableData = []
      this.getportalPageList()
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  ::v-deep .ql-editor {
    min-height: 200px;
  }

  .el-date-editor ::v-deep .el-range-input {
    background: transparent;
  }

  .search {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    .back {
      padding: 0;
      margin-right: auto;
    }
    .el-input {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .el-autocomplete,
    .el-input,
    .el-select {
      width: 160px;
      margin-bottom: 20px;
    }

    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  // .el-input-number {
  //   width: 255px;
  // }

  .table_list {
    width: 100%;
    box-sizing: border-box;
    overflow-x: auto;

    .desc {
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .red {
      color: #eb2500;
    }

    .green {
      color: #c27c88;
    }

    .gray {
      color: #68706e;
    }

    .logo {
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 80px;
      height: 58px;
      border-radius: 4px;
    }

    ::v-deep .el-table__fixed-header-wrapper thead tr th {
      color: rgba(3, 16, 14, 0.9);
      background-color: #f4f6f9;
    }
  }
}
</style>
